var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Prevent closing"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codePrevent) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To prevent ")]), _c('code', [_vm._v("<b-modal>")]), _c('span', [_vm._v(" from closing (for example when validation fails). you can call the ")]), _c('code', [_vm._v(".preventDefault()")]), _c('span', [_vm._v(" method of the event object passed to your ")]), _c('code', [_vm._v("ok")]), _c('span', [_vm._v(" (OK button), ")]), _c('code', [_vm._v("cancel")]), _c('span', [_vm._v(" (Cancel button), ")]), _c('code', [_vm._v("close")]), _c('span', [_vm._v(" (modal header close button) and ")]), _c('code', [_vm._v("hide")]), _c('span', [_vm._v(" event handlers.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-prevent-closing",
      modifiers: {
        "modal-prevent-closing": true
      }
    }],
    attrs: {
      "id": "toggle-btn",
      "variant": "outline-primary"
    }
  }, [_vm._v(" Open Modal ")]), _c('div', {
    staticClass: "mt-2"
  }, [_c('p', [_vm._v("Submitted Names:")]), _vm.submittedNames.length === 0 ? _c('div', [_vm._v(" -- ")]) : _c('b-list-group', _vm._l(_vm.submittedNames, function (data, index) {
    return _c('b-list-group-item', {
      key: index
    }, [_vm._v(" " + _vm._s(data) + " ")]);
  }), 1)], 1), _c('b-modal', {
    ref: "my-modal",
    attrs: {
      "id": "modal-prevent-closing",
      "title": "Submit Your Name",
      "ok-title": "Submit",
      "cancel-variant": "outline-secondary"
    },
    on: {
      "show": _vm.resetModal,
      "hidden": _vm.resetModal,
      "ok": _vm.handleOk
    }
  }, [_c('form', {
    ref: "form",
    on: {
      "submit": function submit($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.handleSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "state": _vm.nameState,
      "label": "Name",
      "label-for": "name-input",
      "invalid-feedback": "Name is required"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "name-input",
      "state": _vm.nameState,
      "required": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }