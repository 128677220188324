var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Disabling built-in footer buttons"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeDisableFooter) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("You can disable the Cancel and OK buttons individually by setting the ")]), _c('code', [_vm._v("cancel-disabled")]), _c('span', [_vm._v(" and ")]), _c('code', [_vm._v("ok-disabled")]), _c('span', [_vm._v(" props, respectively, to ")]), _c('code', [_vm._v("true")]), _c('span', [_vm._v(". Set the prop to ")]), _c('code', [_vm._v("false")]), _c('span', [_vm._v(" to re-enable the button.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-no-footer",
      modifiers: {
        "modal-no-footer": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Launch Modal ")]), _c('b-modal', {
    attrs: {
      "id": "modal-no-footer",
      "title": "BootstrapVue",
      "cancel-disabled": "",
      "ok-disabled": "",
      "cancel-title": "Close",
      "cancel-variant": "outline-secondary",
      "ok-title": "Accept"
    }
  }, [_c('b-card-text', [_vm._v(" Tootsie roll oat cake I love bear claw I love caramels caramels halvah chocolate bar. Cotton candy gummi bears pudding pie apple pie cookie. Cheesecake jujubes lemon drops danish dessert I love caramels powder. ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }