var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Footer button size"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeFooterSize) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("Fancy smaller or larger buttons in the default footer? Simply set the")]), _c('code', [_vm._v("button-size")]), _c('span', [_vm._v("prop to")]), _c('code', [_vm._v("'sm'")]), _c('span', [_vm._v("for small buttons, or")]), _c('code', [_vm._v(" 'lg'")]), _c('span', [_vm._v("for larger buttons.")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-footer-sm",
      modifiers: {
        "modal-footer-sm": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Small Footer Buttons ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-footer-lg",
      modifiers: {
        "modal-footer-lg": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Large Footer Buttons ")])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-footer-sm",
      "title": "BootstrapVue",
      "button-size": "sm",
      "ok-title": "Accept",
      "cancel-title": "Close",
      "cancel-variant": "outline-secondary"
    }
  }, [_c('b-card-text', [_vm._v(" This modal has small footer buttons. Lorem ipsum dolor sit amet consectetur adipisicing elit. A cumque quo delectus, aspernatur quasi sint vitae reiciendis quae? Itaque minima atque quae corporis impedit repellat recusandae consectetur voluptas, at rerum? ")])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-footer-lg",
      "title": "BootstrapVue",
      "button-size": "lg",
      "cancel-title": "Close",
      "ok-title": "Accept",
      "cancel-variant": "outline-secondary"
    }
  }, [_c('b-card-text', [_vm._v(" This modal has large footer buttons. Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus eligendi, dolorem consequuntur delectus necessitatibus eum expedita culpa laudantium! Quaerat debitis obcaecati doloremque a iusto, soluta ipsa velit. Veritatis, assumenda sapiente? ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }