var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Modal Themes"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeThemes) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("Use class ")]), _c('code', [_vm._v("modal-class=\"modal-{color}\"")]), _c('span', [_vm._v(" with your ")]), _c('code', [_vm._v("<b-modal>")]), _c('span', [_vm._v(" to change theme of modal")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-primary",
      modifiers: {
        "modal-primary": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Primary ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-seconday",
      modifiers: {
        "modal-seconday": true
      }
    }],
    attrs: {
      "variant": "outline-secondary"
    }
  }, [_vm._v(" Secondary ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(40, 199, 111, 0.15)',
      expression: "'rgba(40, 199, 111, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-success",
      modifiers: {
        "modal-success": true
      }
    }],
    attrs: {
      "variant": "outline-success"
    }
  }, [_vm._v(" Success ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-danger",
      modifiers: {
        "modal-danger": true
      }
    }],
    attrs: {
      "variant": "outline-danger"
    }
  }, [_vm._v(" Danger ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 159, 67, 0.15)',
      expression: "'rgba(255, 159, 67, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-warning",
      modifiers: {
        "modal-warning": true
      }
    }],
    attrs: {
      "variant": "outline-warning"
    }
  }, [_vm._v(" Warning ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(0, 207, 232, 0.15)',
      expression: "'rgba(0, 207, 232, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-info",
      modifiers: {
        "modal-info": true
      }
    }],
    attrs: {
      "variant": "outline-info"
    }
  }, [_vm._v(" Info ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(30, 30, 30, 0.15)',
      expression: "'rgba(30, 30, 30, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-dark",
      modifiers: {
        "modal-dark": true
      }
    }],
    attrs: {
      "variant": "outline-dark"
    }
  }, [_vm._v(" Dark ")])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-primary",
      "ok-only": "",
      "ok-title": "Accept",
      "modal-class": "modal-primary",
      "centered": "",
      "title": "Primary Modal"
    }
  }, [_c('b-card-text', [_vm._v(" Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly. ")])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-seconday",
      "ok-only": "",
      "ok-variant": "secondary",
      "ok-title": "Accept",
      "modal-class": "modal-secondary",
      "centered": "",
      "title": "Secondary Modal"
    }
  }, [_c('b-card-text', [_vm._v(" Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly. ")])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-success",
      "ok-only": "",
      "ok-variant": "success",
      "ok-title": "Accept",
      "modal-class": "modal-success",
      "centered": "",
      "title": "Success Modal"
    }
  }, [_c('b-card-text', [_vm._v(" Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly. ")])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-danger",
      "ok-only": "",
      "ok-variant": "danger",
      "ok-title": "Accept",
      "modal-class": "modal-danger",
      "centered": "",
      "title": "Danger Modal"
    }
  }, [_c('b-card-text', [_vm._v(" Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly. ")])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-info",
      "ok-only": "",
      "ok-variant": "info",
      "ok-title": "Accept",
      "modal-class": "modal-info",
      "centered": "",
      "title": "Info Modal"
    }
  }, [_c('b-card-text', [_vm._v(" Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly. ")])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-warning",
      "ok-only": "",
      "ok-variant": "warning",
      "ok-title": "Accept",
      "modal-class": "modal-warning",
      "centered": "",
      "title": "Warning Modal"
    }
  }, [_c('b-card-text', [_vm._v(" Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly. ")])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-dark",
      "ok-only": "",
      "ok-variant": "dark",
      "ok-title": "Accept",
      "modal-class": "modal-dark",
      "centered": "",
      "title": "Dark Modal"
    }
  }, [_c('b-card-text', [_vm._v(" Biscuit chocolate cake gummies. Lollipop I love macaroon bear claw caramels. I love marshmallow tiramisu I love fruitcake I love gummi bears. Carrot cake topping liquorice. Pudding caramels liquorice sweet I love. Donut powder cupcake ice cream tootsie roll jelly. ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }