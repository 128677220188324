var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Multiple modal support"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeMultiple) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("To disable stacking for a specific modal, just set the prop ")]), _c('code', [_vm._v("no-stacking")]), _c('span', [_vm._v("on the")]), _c('code', [_vm._v("<b-modal>")]), _c('span', [_vm._v("component. This will hide the modal before another modal is shown.")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-multi-1",
      modifiers: {
        "modal-multi-1": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Open First Modal ")]), _c('b-modal', {
    attrs: {
      "id": "modal-multi-1",
      "size": "lg",
      "title": "First Modal",
      "ok-only": "",
      "ok-title": "Accept",
      "no-stacking": ""
    }
  }, [_c('b-card-text', {
    staticClass: "my-2"
  }, [_vm._v(" First Modal ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-multi-2",
      modifiers: {
        "modal-multi-2": true
      }
    }],
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Open Second Modal ")])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-multi-2",
      "title": "Second Modal",
      "ok-only": "",
      "ok-title": "Accept"
    }
  }, [_c('b-card-text', {
    staticClass: "my-2"
  }, [_vm._v(" Second Modal ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }, {
      name: "b-modal",
      rawName: "v-b-modal.modal-multi-3",
      modifiers: {
        "modal-multi-3": true
      }
    }],
    attrs: {
      "size": "sm",
      "variant": "primary"
    }
  }, [_vm._v(" Open Third Modal ")])], 1), _c('b-modal', {
    attrs: {
      "id": "modal-multi-3",
      "size": "sm",
      "title": "Third Modal",
      "ok-only": "",
      "ok-title": "Accept"
    }
  }, [_c('b-card-text', {
    staticClass: "my-1"
  }, [_vm._v(" Third Modal ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }